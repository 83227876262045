/**
 * @description 判断当前客户端
 * @returns {string}
 */
export const JUDGE_CLIENT = () => {
    const u = navigator.userAgent
    const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //判断是否是 android终端
    const isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //判断是否是 iOS终端
    if (isAndroid) {
        return 'android'
    } else if (isIOS) {
        return 'ios'
    } else {
        return 'pc'
    }
}

/**
 * @description 获取URL上的参数
 * @param name {string} 参数名称
 * @returns {string | null}
 */
export const GetQueryString = (name: string) => {
    const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
    const r = window.location.search.substr(1).match(reg) //search,查询？后面的参数，并匹配正则
    if (r != null) return unescape(r[2])
    return null
}
