export default [
    /* ------------------------------------------------------ */
    // 桃鹿用户端下载
    {
        path: '/deerC',
        name: 'deerC',
        component: () => import('@/views/pc/deer/deerC.vue'),
        meta: {
            title: '桃鹿',
            icon: 'deerC'
        }
    },
    // 桃鹿主播端下载
    {
        path: '/deerA',
        name: 'deerA',
        component: () => import('@/views/pc/deer/deerA.vue'),
        meta: {
            title: '桃鹿助手',
            icon: 'deerA'
        }
    },
    /* ------------------------------------------------------ */
    // 网址安全栅栏导航
    {
        path: '/nav',
        name: 'nav',
        component: () => import('@/views/pc/nav/index.vue'),
        meta: {
            title: '桓创科技'
        }
    },
    /* ------------------------------------------------------ */
    // 套路直播
    {
        path: '/tl',
        name: 'tl',
        component: () => import('@/views/pc/tl/index.vue'),
        meta: {
            title: '套路直播',
            icon: 'tl',
            content: {
                keywords:
                    '套路直播,套路直播官网,套路直播下载地址,套路视频社交,套路视频社交官网,套路视频社交下载地址,字母圈,字母圈论坛,字母圈软件,BDSM,sm软件,美腿丝袜,高跟鞋控,帆布鞋控,足控,玉足图片,丝袜控,裸足照片',
                description: '看美女直播、玩直播，尽在套路直播'
            }
        }
    }
]
