import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// 样式初始化
import '@/assets/style/common.css'
// 动画样式
import '@/assets/style/animation.css'

// 引入UI组件库
import { NavBar, Popup, CountDown, Loading, Field, Swipe, SwipeItem, Button } from 'vant'
import 'vant/lib/index.css'
// UI组件库样式修改
import '@/assets/style/vant-reset.css'
// 适配pc端
import '@vant/touch-emulator'

// 全局组件
import vueQr from 'vue-qr/src/packages/vue-qr.vue'

// 移动端调试
import VConsole from 'vconsole'
import { JUDGE_CLIENT } from '@/utils/common'
if (process.env.VUE_APP_FLAG !== 'prod' && JUDGE_CLIENT() !== 'pc') {
    new VConsole()
}

createApp(App)
    .use(router)
    .use(NavBar)
    .use(Popup)
    .use(CountDown)
    .use(Loading)
    .use(Field)
    .use(Swipe)
    .use(SwipeItem)
    .use(Button)
    .component('vue-qr', vueQr)
    .mount('#app')
